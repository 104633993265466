.resultScore {
    font-size: 4em;
    font-weight: 500;
    line-height: 1.5em;
}

.resultLevel {
    font-size: 1.5em;
}

/* https://loading.io/css/ */
.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}
.lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 6px;
    box-sizing: border-box;
    border: 26px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        transform: rotate(1800deg);
    }
}


.lds-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}
.lds-ripple div {
    position: absolute;
    border: 4px solid #999;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes lds-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}

@media (min-width: 601px) {
    .backgrounded-container {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

@media (max-width: 600px) {
    .backgrounded-container > .card {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -o-box-shadow: none;
        border-radius: 0;
    }

    .backgrounded-overlay {
        background: white !important;
    }

}

.App.container .panel-footer {
        text-align: center;
}

.progress {
    border-radius: 10px;
    margin-top: 2em !important;
}

.progress-bar span {
    position: absolute;
    margin-top: -20px;
    color: black;
}

.progress-bar {
    margin: 0 !important;
}

.pretty label {
    white-space: normal !important;
    line-height: 1.5em;
}

.pretty .state label:after, .pretty .state label:before {
    top: 0 !important;
}

.App .sv_qstn {
    overflow: hidden !important;
}

.sv_q_matrix {
    width: auto;
    max-width: 90%;
}

.checkbox label input[type=checkbox], .radio label input[type=radio], label.checkbox-inline input[type=checkbox], label.radio-inline input[type=radio] {
    margin-left: -20px
}

.sv_qstn .sv_q_imgsel label > div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 4px;
    border-radius: 4px;
    border: none;
}

.sv_qstn .sv_q_imgsel label > div > img, .sv_qstn .sv_q_imgsel label > div > embed {
    display: block;
    pointer-events: none;
    max-height: 100px;
}

.br-theme-css-stars .br-widget a:after {
    content: "\2605";
    color: #d2d2d2;
    font-size: 40px;
}

.br-theme-css-stars .br-widget a {
    background-color: white;
    border: none;
    padding: 0 0 0 0;
    text-decoration: none;
    float: none;
    font-size: 23px;
    margin-right: 5px;
    height: auto;
    width: auto;
}

/* custom CSS */
:root {
    --primary: #F16621;
    --primary-hover: #d2632c;
    --primary-light: rgba(241, 102, 33, 0.1);
}
.btn {
    border-radius: 30px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px 25px;
    font-size: 13px;
}
.btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
}
.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
}
.btn-primary:focus,
.btn-secondary:focus {
    box-shadow: none;
}
.btn-secondary {
    background-color: #FFFFFF;
    border-color: var(--primary);
    color: var(--primary);
    border-width: 2px;
}
.btn-secondary:hover,
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
}
.custom-control-input:checked~.custom-control-label::before {
    border-color: var(--primary);
    background-color: var(--primary);
}
.alert-info {
    border-color: var(--primary-light);
    background-color: var(--primary-light);
    color: var(--primary);
    font-size: 14px;
    padding: 10px;
}
.nav-tabs {
    border-bottom-color: var(--primary);
}
.nav-tabs .nav-link {
    text-transform: uppercase;
    color: #212529;
    font-size: 14px;
    padding: 10px 30px;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: transparent;
    color: var(--primary);
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border: 0;
    font-weight: 700;
    color: var(--primary);
    border-bottom: 5px solid var(--primary);
}
.sv-button-group label {
    margin-bottom: 0;
}
.svc-item-value-controls__add use {
    fill: #19b394 !important;
}
.tab-content label,
.form-group label {
    font-weight: 500;
}
.form-control:focus {
    border-color: var(--primary-light);
    box-shadow: 0 0 0 0.2rem rgb(241, 102, 33, 0.25);
}
.custumizeSurveyCard {
    border-radius: 15px;
    margin: 0 15px;
}
.surveyCreatorLogo {
    box-shadow: 1px 1px 20px rgba(0,0,0,0.2);
    display: inline-block;
    padding: 0 30px 10px;
    border-radius: 0 0 15px 15px;
}
.surveyCreatorLogo img {
    width: 100px;
}
.surveyCreatorContainer {
    margin: 10px 0 20px;
}
.surveyCreatorCustomForm {
    margin-bottom: 30px;
}
.surveyCreatorCustomFormActions {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.custom-file-label {
    left: -1px;
    top: -1px;
}
.form-group.forInputFile {
    position: relative;
    margin-bottom: 25px;
}
@media screen and (min-width: 992px) {
    .surveyCreatorCustomForm {
        padding-left: 260px;
    }
    .surveyCreatorCustomForm .form-group {
        margin-bottom: 0;
    }
    .surveyCreatorLogo {
        position: absolute;
        left: 30px;
        top: 0;
    }
    .surveyCreatorLogo img {
        width: 140px;
    }
    .surveyCreatorCustomFormActions {
        min-height: 85px;
    }
}

.lds-dual-ring {
    display: block;
    width: 40px;
    height: 40px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 34px;
    height: 34px;
    margin: 8px;
    border-radius: 50%;
    border: 3px solid #ccc;
    border-color: #ccc transparent #ccc transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.deleteUploadFile {
    position: absolute;
    background: white;
    right: 0px;
    top: 0px;
    font-size: 15px;
    width: 20px;
    padding: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.previewUploadFile {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    position: relative;
    width: max-content;
    box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
}

.br-theme-css-stars .br-widget a {
    width: 25px !important;
    height: 25px !important;
}
.br-theme-css-stars .br-widget a:after {
    font-size: 25px;
}

.br-theme-css-stars .br-widget a[data-rating-value="newitem"],
.br-theme-css-stars .br-widget a[data-rating-value="none"],
.br-theme-css-stars .br-widget a[data-rating-value="other"] {
    display: none;
}