body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

@media only screen and (max-width: 480px) {
    .sv_qstn fieldset .sv-q-col-5 {
        width: 20%;
        /* margin-right: 0; */
        display: inline-block;
        padding: 5px;
    }
    .sv_qstn fieldset .sv-q-col-5 img {
        object-fit: scale-down !important;
        width: 100%;
        height: 50px;
    }
}

.sv_qstn fieldset .sv-q-col-5 {
    width: 20% !important;
    /* margin-right: 0; */
    display: inline-block;
    padding: 5px;
}

.sv_qstn fieldset .sv-q-col-5 img {
    max-width: 100%;
}

.sv_qstn .sv_q_imgsel label > div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 4px;
    border-radius: 4px;
    border: none;
}

.sv_qstn .sv_q_imgsel label > div > img, .sv_qstn .sv_q_imgsel label > div > embed {
    display: block;
    pointer-events: none;
    max-height: 100px;
}

.custom-rating {
    width: 670px;
    margin: 0 auto;
}

.custom-rating > .btn {
    border-radius: 0;
    width: 50px;
}
.custom-rating > .btn:not(:last-child) {
    margin-right: 12px;
}

.custom-rating > .btn:nth-child(1) {
    background-color: red;
}
.custom-rating > .btn:nth-child(2), .custom-rating > .btn:nth-child(3), .custom-rating > .btn:nth-child(4) {
    background-color: yellow;
}

.custom-footer {
    text-align: center;
}
.custom-nav {
    display: inline-block;
}

